import React from "react";
import PropTypes from "prop-types";
import { Image, imageProps, Text, RichText, Icon } from "@ui";
import { getTailwindUtility } from "@utils";

const AboutPageTeamMember = ({ name, photo, email, bio, className }) => {
  const colors = getTailwindUtility("colors");
  const filter = colors.blue["3"].replace("#", "");

  return (
    <div
      className={`relative sm:flex max-w-cb-lg p-8 sm:py-8 sm:pl-0 sm:pr-8 ${className}`}
    >
      <div className="absolute inset-0 md:ml-24 border-2 border-gray-1 rounded" />

      <div className="relative w-48 h-48 rounded-lg overflow-hidden mx-auto mb-6 sm:mb-0">
        <Image image={{ ...photo, filter }} />
      </div>

      <div className="relative z-10 sm:flex-1 sm:pl-8">
        <div className="flex items-end my-4">
          <Text preset="h3">{name}</Text>

          <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
            <Icon name="email" className="text-blue w-5 h-5 ml-4" />
          </a>
        </div>

        <RichText html={bio.html} className="RichText--content-builder" />
      </div>
    </div>
  );
};

AboutPageTeamMember.propTypes = {
  name: PropTypes.string.isRequired,
  photo: imageProps.props.isRequired,
  email: PropTypes.string.isRequired,
  bio: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

AboutPageTeamMember.defaultProps = {
  className: "",
};

export default AboutPageTeamMember;
