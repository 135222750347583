import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import { Image, imageProps, Icon } from "@ui";
import { getTailwindUtility } from "@utils";

const colors = getTailwindUtility("colors");
const filter = colors.blue["3"].replace("#", "");

const AboutPageTeacherVideo = ({ ctaText, backgroundImage, video }) => {
  const [, dispatch] = useAppState();

  const openVideoModal = () => {
    dispatch({
      type: "openModal",
      name: "videoEmbed",
      context: {
        title: video.title,
        youtubeVideoId: video.youtubeVideoId,
      },
    });
  };

  return (
    <button
      type="button"
      className="relative flex w-full items-center justify-center outline-none focus:outline-none rounded-lg overflow-hidden group"
      onClick={openVideoModal}
    >
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-blue-screen z-30" />
        <Image image={{ ...backgroundImage, filter }} />
      </div>
      <div className="relative flex flex-col items-center z-30 py-24">
        <Icon
          name="videoPlayLg"
          className="w-16 h-16 text-white group-hover:scale-110 transition-fast mb-8"
        />
        <span className="font-sans font-bold text-sm text-white border-b-2 border-white transition-fast">
          {ctaText}
        </span>
      </div>
    </button>
  );
};

AboutPageTeacherVideo.propTypes = {
  ctaText: PropTypes.string.isRequired,
  backgroundImage: imageProps.props.isRequired,
  video: PropTypes.shape({
    title: PropTypes.string.isRequired,
    youtubeVideoId: PropTypes.string.isRequired,
  }).isRequired,
};

export default AboutPageTeacherVideo;
