import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { IndexHero } from "@modules/indexes/index";
import { useAboutPageData } from "@staticQueries";
import { PreFooter, SEO, VideosPopout } from "@common";
import {
  Text,
  HeadingAccent,
  RichText,
  Button,
  AppLink,
  Section,
  Container,
} from "@ui";
import AboutPageMediaLogo from "./AboutPageMediaLogo";
import AboutPageTeamMember from "./AboutPageTeamMember";
import AboutPageTeacherVideo from "./AboutPageTeacherVideo";

/* eslint react/prop-types: 0 */

const AboutPage = () => {
  const {
    meta,
    hero,
    origin,
    fact,
    media,
    curriculum,
    team,
    prefooter,
  } = useAboutPageData();

  const [{ breakpoints }, dispatch] = useAppState();
  const { sm } = breakpoints;

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  return (
    <>
      <SEO {...meta} />

      <IndexHero heading={hero.heading} description={hero.description} />

      {/* Origin */}
      <Section>
        <Container>
          <div className="max-w-cb mx-auto">
            <HeadingAccent />
            <Text preset="h2" className="text-blue mb-6">
              {origin.heading}
            </Text>

            <RichText
              html={origin.copy.html}
              className="RichText--content-builder RichText--lg"
            />
          </div>
        </Container>
      </Section>

      {/* Fact Checkers */}
      <Section className="bg-gray-1 lg:rounded-lg">
        <Container className="md:flex">
          <div className="md:flex-1 md:pr-16">
            <HeadingAccent />
            <Text preset="h2" className="text-blue mb-6">
              {fact.heading}
            </Text>

            <RichText
              html={fact.copy.html}
              className="RichText--content-builder"
            />
          </div>

          <div className="bg-gray-2-20 md:w-2/5 rounded-lg p-6 md:p-12 mt-6 md:mt-0">
            <Text preset="h3" className="text-center">
              {fact.corQuestionHeading}
            </Text>

            {fact.corQuestions.map(({ title, color }, idx) => (
              <div key={idx} className={`bg-${color.toLowerCase()} p-3 mt-6`}>
                <Text preset="h4" className="text-center">
                  {title}
                </Text>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      {/* Media */}
      <Section>
        <Container>
          <div className="text-center md:mb-6">
            <HeadingAccent />
            <Text preset="h2" className="text-blue">
              {media.heading}
            </Text>
          </div>

          <div className="md:flex">
            <div className="md:flex-1 md:pr-3 my-8">
              <div className="bg-gray-1 rounded-lg flex flex-wrap justify-center sm:justify-between p-6">
                {media.publicationLogos.map(logo => (
                  <AboutPageMediaLogo
                    key={logo.id}
                    logo={logo}
                    height={sm ? 42 : 36}
                    className="my-4 md:my-6 mx-4 md:mx-3"
                  />
                ))}
              </div>
            </div>

            <div className="md:flex-1 md:pl-3 md:flex md:flex-col my-8">
              <div className="md:flex-1 bg-gray-1 rounded-lg flex items-center justify-center p-6">
                <AboutPageMediaLogo logo={media.partnerLogo} height={72} />
              </div>
            </div>
          </div>

          <RichText
            html={media.copy.html}
            className="RichText--content-builder columns-none sm:columns-2"
          />
        </Container>
      </Section>

      {/* Curriculum */}
      <Section className="bg-blue-4 lg:rounded-lg">
        <Container className="md:flex mb-8 md:mb-16 lg:mb-24">
          <div className="md:w-1/2 mb-8 md:mb-0 md:pr-8">
            <HeadingAccent />
            <Text preset="h2" className="text-white mb-6">
              {curriculum.heading}
            </Text>

            <RichText
              html={curriculum.copy.html}
              className="RichText--content-builder text-white mb-6"
            />

            <AppLink to="/curriculum/">
              <Button
                preset="empty"
                text={curriculum.ctaText}
                className="text-white"
              />
            </AppLink>
          </div>

          <div className="md:w-1/2 md:pl-8 md:py-24">
            <AboutPageTeacherVideo
              backgroundImage={curriculum.video.backgroundImage}
              ctaText={curriculum.video.ctaText}
              video={curriculum.video.video}
            />
          </div>
        </Container>

        <VideosPopout preset="light" />
      </Section>

      {/* Team */}
      <Section>
        <Container>
          <div className="max-w-cb mb-6 md:mb-12">
            <HeadingAccent />
            <Text preset="h2" className="text-blue mb-6">
              {team.heading}
            </Text>

            <RichText
              html={team.copy.html}
              className="RichText--content-builder"
            />
          </div>

          {team.members.map((member, idx) => (
            <AboutPageTeamMember
              key={idx}
              name={member.name}
              photo={member.photo}
              email={member.email}
              bio={member.bio}
              className={idx < team.members.length - 1 ? "mb-6" : "mb-0"}
            />
          ))}
        </Container>
      </Section>

      <PreFooter {...prefooter} />
    </>
  );
};

export default AboutPage;
