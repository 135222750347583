import React from "react";
import PropTypes from "prop-types";
import { Image, imageProps } from "@ui";

const AboutPageMediaLogo = ({ logo, height, className }) => (
  <span
    className={`relative inline-block ${className}`}
    style={{ height: `${height}px` }}
  >
    <Image
      image={logo}
      objectFit="contain"
      forceHeight={height}
      showLQIP={false}
    />
  </span>
);

AboutPageMediaLogo.propTypes = {
  logo: imageProps.props.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
};

AboutPageMediaLogo.defaultProps = {
  className: "",
};

export default AboutPageMediaLogo;
